import jwt from "jwt-decode";
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Route, Routes, useNavigate } from "react-router-dom";
import Cookies from './components/public/Cookies'
import PrivacyPolicy from './components/public/PrivacyPolicy'
import "./App.css";

// components
import Grid from "@mui/material/Grid";

// style
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { style } from "./style/style";
import { theme } from "./style/theme";

// components
import Login from "./components/signup/Login";

// services
import { LocalStorageService } from "./services/LocalStorageService";
import { SignupService } from "./services/SignupService";
import { getAccessToken } from "./services/WebServiceClient";

//head
import { Helmet } from "react-helmet";
import Dashboard from "./components/dashboard/Dashboard";

import ReactGA from "react-ga4";
import AppContext from "./AppContext";
import Landing from "./components/signup/Landing";
import EmailReset from "./components/signup/EmailReset";
import Create from "./components/signup/Create";
import Password from "./components/signup/Password";
import CreatePassword from "./components/signup/CreatePassword";

ReactGA.initialize("G-C8XHDSW4T2");

function App() {
  const navigate = useNavigate();
  const org = JSON.parse(LocalStorageService.get("organisation"));
  const user = JSON.parse(LocalStorageService.get("user"));
  const [stack, setStack] = useState([]);
  const publicPages = ["/privacy-policy", "/cookies", "/landing"];
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [window.location.pathname]);

  useEffect(() => {
    // allow anyone to access these pages
    if (publicPages.includes(window.location.pathname)) {
      return;
    }

    let init = async () => {
      let token = getAccessToken();
      let decoded = token ? jwt(token) : null;

      // Dont do anything on the login/landing page
      if (/(\/login)+$/g.exec(window.location.href)) return;
      if (/(\/landing)+$/g.exec(window.location.href)) return;
      if (/(\/create-password)+$/g.exec(window.location.href)) return;

      // allow anyone to access public pages
      if (publicPages.includes(window.location.pathname)) { return }

      // If we have no valid token/no valid regresh token
      if (!decoded || Date.now() >= decoded.exp * 1000) {
        if (await SignupService.refresh()) {
          setTimeout(() => {
            SignupService.refresh();
          }, 3 * 60 * 1000);

          navigate("/dashboard", {
            state: {
              user: user,
              org: org,
              token: getAccessToken(),
            },
          });
        } else {
          navigate("/landing");
        }
      }
      // We have everthing we need
      else {
        navigate("/dashboard", {
          state: {
            user: user,
            org: org,
            token: token,
          },
        });
      }
    };

    init();
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [window.location.pathname])

  const global = { stack, setStack };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=0.86, maximum-scale=5.0, minimum-scale=0.86"
        ></meta>
      </Helmet>
      <CssBaseline />
      <AppContext.Provider value={global}>
        <Grid container spacing={2} style={style.app}>
          <Routes>
            <Route
              path="/dashboard"
              element={<Dashboard spacing={theme.spacing} />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/login" element={<Login spacing={theme.spacing} />} />
            <Route
              path="/landing"
              element={<Landing spacing={theme.spacing} />}
            />
            <Route
              path="/activate"
              element={<Create spacing={theme.spacing} />}
            />
            <Route
              path="/create-password"
              element={<CreatePassword spacing={theme.spacing} />}
            />
            <Route
              path="/reset"
              element={<EmailReset spacing={theme.spacing} />}
            />
            <Route
              path="/reset-password"
              element={<Password spacing={theme.spacing} />}
            />
          </Routes>
          <CookieConsent
            location="bottom"
            buttonText="Okay"
            cookieName="cookieAcceptance"
            style={{
              background: "#8b8e8d",
              borderTopWidth: 1,
              borderTopColor: "#000",
            }}
            buttonStyle={{
              background: "#50bba2",
              color: "#fff",
              fontSize: "12px",
              borderRadius: "3px",
            }}
            expires={150}
          >
            By using this site you agreed to our{" "}
            <a
              rel="noreferrer"
              href="/cookies"
              style={{ color: "#ddd" }}
              target="_blank"
            >
              cookie
            </a>{" "}
            and{" "}
            <a
              rel="noreferrer"
              href="/privacy-policy"
              style={{ color: "#ddd" }}
              target="_blank"
            >
              privacy policy
            </a>
            .
          </CookieConsent>
        </Grid>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
