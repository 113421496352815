// React
import moment from "moment";
import { React, useEffect, useState } from "react";
import ReactGA from 'react-ga4';

// Appt
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

// data
import { questionnaire } from "../../../../resources/question-data";

// style
import { style } from "../../../../style/style";

// services
import { LocalStorageService } from "../../../../services/LocalStorageService";
import { WebServiceClient, getAccessToken } from "../../../../services/WebServiceClient";

// components
import QuestionBoolean from "./QuestionBoolean";
import QuestionCheckbox from "./QuestionCheckbox";
import QuestionDate2 from "./QuestionDate2";
import QuestionMulti from "./QuestionMulti";
import QuestionOption from "./QuestionOption";
import QuestionSelect from "./QuestionSelect";
import QuestionTextField from "./QuestionTextField";

import { Stack } from "@mui/material";
import { actionType } from "../../../../enums/ActionType";
import { questionType } from "../../../../enums/QuestionType";
import QuestionOccupier from "./QuestionOccupier";
import SuccessPage from "./SuccessPage";

function Questions(props) {
  const totalQuestions = questionnaire.questions.length;
  const org = JSON.parse(LocalStorageService.get("organisation"));
  const user = JSON.parse(LocalStorageService.get("user"));
  const token = getAccessToken();

  const [moreInfo, setMoreInfo] = useState();
  const [question, setQuestion] = useState({});
  const [currentIndex, setCurrentIndex] = useState(8);
  const [formValues, setFormValues] = useState();
  const [defaultValues, setDefaultValues] = useState();

  const [progress, setProgress] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(1);
  const [isRequired, setIsRequired] = useState(false);
  const [formValuesMulti, setFormValuesMulti] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [questionnaireId, setQuestionnaireId] = useState();
  const [multiRequired, setMultiRequired] = useState([]);
  const [questionStack, setQuestionStack] = useState([]);
  const [questionTextArray, setQuestionTextArray] = useState([]);

  const getMoreInfo = () => {
    switch (moreInfo?.what?.questionType) {
      case questionType.PLAINTEXT:
        return (
          <Typography style={{ color: "black", padding: "20px" }}>
            {moreInfo?.what?.text}
          </Typography>
        );

      case questionType.DATE:
        return (
          <QuestionDate2
            question={moreInfo?.what}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            formValues={formValues}
          />
        );

      case questionType.MULTITEXT:
        return (
          <QuestionMulti
            question={moreInfo?.what}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            multiRequired={multiRequired}
            formValues={formValues}
          />
        );

      case questionType.OCCUPIER:
        return (
          <QuestionOccupier
            question={moreInfo?.what}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            multiRequired={multiRequired}
            formValues={formValues}
          />
        );

      case questionType.TEXT:
        return (
          <QuestionTextField
            question={moreInfo?.what}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            formValues={formValues}
          />
        );
      default:
        return;
    }
  };

  const getType = (question) => {
    switch (question.questionType) {
      case questionType.DATE:
        return (
          //<QuestionDate question={question} handleOnChange={handleOnChange} />
          <QuestionDate2
            question={question}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            formValues={formValues}
          />
        );

      case questionType.TEXT:
        return (
          <QuestionTextField
            question={question}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            formValues={formValues}
          />
        );
      case questionType.MULTITEXT:
        return (
          <QuestionMulti
            question={question}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            multiRequired={multiRequired}
            formValues={formValues}
          />
        );
      case questionType.OCCUPIER:
        return (
          <QuestionOccupier
            question={question}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            multiRequired={multiRequired}
            formValues={formValues}
          />
        );
      case questionType.SELECT:
        return (
          <QuestionSelect
            question={question}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            formValues={formValues}
          />
        );
      case questionType.BOOLEAN:
        return (
          <QuestionBoolean
            question={question}
            getAction={getAction}
            handleOnChange={handleOnChange}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            formValues={formValues}
          />
        );
      case questionType.CHECKBOX:
        return (
          <QuestionCheckbox
            question={question}
            handleOnChange={handleOnChange}
            formValues={formValues}
          />
        );
      case questionType.OPTION:
        return (
          <QuestionOption
            question={question}
            handleOnChange={handleOnChange}
            getAction={getAction}
            formValuesMulti={formValuesMulti}
            setFormValuesMulti={setFormValuesMulti}
            formValues={formValues}
          />
        );

      case questionType.PLAINTEXT:
        return (
          <Typography style={{ color: "black", padding: "20px" }}>
            {moreInfo?.what?.text}
          </Typography>
        );

      default:
    }
  };

  const getAction = (action) => {
    switch (action.type) {
      case actionType.GOTO:
        if (action.where !== 0) {
          setMoreInfo();
        }
        break;
      case actionType.MOREINFO:
        setMoreInfo(action);
        break;
      case actionType.NONE:
        setMoreInfo();
        break;
      default:
    }
  };

  useEffect(() => {
    if (moreInfo) {
      getFormValues(moreInfo.what, defaultValues);
    }
  }, [moreInfo]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/questionnaire" });
    ReactGA.event({
      category: "Questionnaire",
      action: "Start",
    });
  }, []);

  useEffect(() => {
    if (questionnaire.questions.length > 0) {
      setQuestion(
        questionnaire.questions.find((q) => q.questionId === currentIndex)
      );
    }
    const config = {
      headers: { Authorization: token },
    };
    const params = {
      orgId: org.id,
      siteId: null,
    };

    WebServiceClient.get("/mortgage/borrowers/active", params, config)
      .then(function (response) {
        if (response.data) {
          let questionaire = response.data.find(
            (q) => q.MatterReference === props.mortgage.MatterReference
          );

          if (questionaire.Mortgage.questionaireId) {
            setQuestionnaireId(questionaire.Mortgage.questionaireId);
          }
          if (questionaire.Mortgage.Questionaire) {
            if (questionaire.Mortgage.Questionaire.extraJSON) {
              let values = {
                ...questionaire.Mortgage.Questionaire,
                ...questionaire.Mortgage.Questionaire.extraJSON,
              };
              setDefaultValues(values);
            } else {
              setDefaultValues(questionaire.Mortgage.Questionaire);
            }
          } else {
            getFormValues(question, defaultValues);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setFormValues(defaultValues);
    getFormValues(question, defaultValues);
    checkMoreInfo(defaultValues, question);
  }, [defaultValues]);

  const checkMoreInfo = (values, question) => {
    if (
      question.questionType === questionType.OPTION ||
      question.questionType === questionType.BOOLEAN
    ) {
      if (
        values &&
        values[question.name] !== undefined &&
        values[question.name] !== null &&
        values[question.name] !== ""
      ) {
        let action = question.actions.find(
          (a) => a.when === values[question.name]
        );
        if (action?.type === actionType.MOREINFO) {
          setMoreInfo(action);
        } else {
          setMoreInfo();
        }
      } else {
        setMoreInfo();
      }
    } else {
      setMoreInfo();
    }
  };

  useEffect(() => {
    let nextQuestion = questionnaire.questions.find(
      (q) => q.questionId === currentIndex
    );

    ReactGA.event({
      category: "Questionnaire",
      action: nextQuestion?.section,
      label: currentIndex,
    });

    let nextQ = { ...nextQuestion };
    if (nextQ) {
      if (currentIndex === 53 && props.mortgage.Mortgage.IsLBG) {
        let text =
          "Apart from the borrowers, does anyone else aged 17 or over live in the property such as spouse, partner, lodger or carer?  Do not include children, stepchildren or foster children";
        if (nextQ.labelText) {
          nextQ.labelText = text;
        } else {
          nextQ.questionText = text;
        }
      }

      if (currentIndex === 56) {
        let text = props.mortgage.Mortgage.MatterAdminDetails.TIDFee
          ? props.mortgage.Mortgage.MatterAdminDetails.TIDFee
          : "0";
        if (nextQ.labelText) {
          nextQ.labelText = nextQ.labelText.replace("{TID CHARGE}", text);
        } else {
          nextQ.questionText = nextQ.questionText.replace("{TID CHARGE}", text);
        }
      }

      if (currentIndex === 10) {
        let text = getAddress();
        if (nextQ.labelText) {
          nextQ.labelText = nextQ.labelText + text;
        } else {
          nextQ.questionText = nextQ.questionText + text;
        }
      }

      setQuestion(nextQ);
      checkMoreInfo(formValues, nextQ);
    }
  }, [currentIndex]);

  const getAddress = () => {
    if (
      props.mortgage &&
      props.mortgage.Mortgage &&
      props.mortgage.Mortgage.PropertyDetails
    ) {
      let property = props.mortgage.Mortgage.PropertyDetails;
      return property
        ? (property?.RemortgageAddressLine1
            ? property?.RemortgageAddressLine1
            : "") +
            " " +
            (property?.RemortgageAddressLine2
              ? property?.RemortgageAddressLine2
              : "") +
            " " +
            (property?.RemortgageAddressLine3
              ? property?.RemortgageAddressLine3
              : "") +
            " " +
            (property?.RemortgageAddressPostcode
              ? property?.RemortgageAddressPostcode
              : "")
        : "";
    }
  };

  const buildQuestion = (response, question, existingQuestionTextArray = []) => {
    let result = {
      questionText: question.questionText,
      questionKey: question.name,
      questionResponse: response?.toString() || '',
    };
    let tempArray = [...existingQuestionTextArray];
    if (tempArray.some((q) => q.questionKey === question.name)) {
      let index = tempArray.findIndex((q) => q.questionKey === question.name);
      tempArray[index] = result;
    } else {
      tempArray.push(result);
    }
    return tempArray
  };

  const handleOnChange = (value, questionData) => {
    if (questionData.questionId === 46) {
      setFormValues((prevState) => ({
        ...prevState,
        [questionData.name]: value === "Adding" || value === "Removing",
      }));
      setFormValues((prevState) => ({
        ...prevState,
        AddingRemovingLegalOwnerValue: value,
      }));

      return;
    }

    // join the first, middle and last name for borrower 1
    if (
      [
        "Borrower1FirstName",
        "Borrower1MiddleName",
        "Borrower1Surname",
      ].includes(questionData.name)
    ) {
      let name = {
        Borrower1FirstName: formValues?.Borrower1FirstName || "",
        Borrower1MiddleName: formValues?.Borrower1MiddleName || "",
        Borrower1Surname: formValues?.Borrower1Surname || "",
      };
      name[questionData.name] = value || "";
      const fullName = [
        name?.Borrower1FirstName,
        name?.Borrower1MiddleName,
        name?.Borrower1Surname,
      ]
        .join(" ")
        .trim();
      setFormValues((prevState) => ({
        ...prevState,
        Borrower1Name: fullName,
      }));
    }

    // join the first, middle and last name for borrower 2
    if (
      [
        "Borrower2FirstName",
        "Borrower2MiddleName",
        "Borrower2Surname",
      ].includes(questionData.name)
    ) {
      let name = {
        Borrower2FirstName: formValues?.Borrower2FirstName || "",
        Borrower2MiddleName: formValues?.Borrower2MiddleName || "",
        Borrower2Surname: formValues?.Borrower2Surname || "",
      };
      name[questionData.name] = value || "";
      const fullName = [
        name?.Borrower2FirstName,
        name?.Borrower2MiddleName,
        name?.Borrower2Surname,
      ]
        .join(" ")
        .trim();
      setFormValues((prevState) => ({
        ...prevState,
        Borrower2Name: fullName,
      }));
    }

    // join the name and address of management company landlord
    if (
      [
        "managementCompanyLandlordInfoName",
        "managementCompanyLandlordInfoAddress",
      ].includes(questionData.name)
    ) {
      let name = {
        managementCompanyLandlordInfoName:
          formValues?.managementCompanyLandlordInfoName || "",
        managementCompanyLandlordInfoAddress:
          formValues?.managementCompanyLandlordInfoAddress || "",
      };
      name[questionData.name] = value || "";
      const fullName = [
        name?.managementCompanyLandlordInfoName,
        name?.managementCompanyLandlordInfoAddress,
      ]
        .join(" ")
        .trim();
      setFormValues((prevState) => ({
        ...prevState,
        managementCompanyLandlordInfo: fullName,
      }));
    }

    // join the first, middle and last name for borrower 2
    if (
      [
        "OccupierNamesName",
        "OccupierNamesRelationship",
        "OccupierNamesAge",
        "OccupierNamesName2",
        "OccupierNamesRelationship2",
        "OccupierNamesAge2",
        "OccupierNamesName3",
        "OccupierNamesRelationship3",
        "OccupierNamesAge3",
        "OccupierNamesName4",
        "OccupierNamesRelationship4",
        "OccupierNamesAge4",
        "OccupierNamesName5",
        "OccupierNamesRelationship5",
        "OccupierNamesAge5",
        "OccupierNamesName6",
        "OccupierNamesRelationship6",
        "OccupierNamesAge6",
        "OccupierNamesName7",
        "OccupierNamesRelationship7",
        "OccupierNamesAge7",
        "OccupierNamesName8",
        "OccupierNamesRelationship8",
        "OccupierNamesAge8",
        "OccupierNamesName9",
        "OccupierNamesRelationship9",
        "OccupierNamesAge9",
        "OccupierNamesName10",
        "OccupierNamesRelationship10",
        "OccupierNamesAge10",

        "OccupierNamesName11",
        "OccupierNamesRelationship11",
        "OccupierNamesAge11",
        "OccupierNamesName12",
        "OccupierNamesRelationship12",
        "OccupierNamesAge12",
        "OccupierNamesName13",
        "OccupierNamesRelationship13",
        "OccupierNamesAge13",
        "OccupierNamesName14",
        "OccupierNamesRelationship14",
        "OccupierNamesAge14",
        "OccupierNamesName15",
        "OccupierNamesRelationship15",
        "OccupierNamesAge15",
        "OccupierNamesName16",
        "OccupierNamesRelationship16",
        "OccupierNamesAge16",
        "OccupierNamesName17",
        "OccupierNamesRelationship17",
        "OccupierNamesAge17",
        "OccupierNamesName18",
        "OccupierNamesRelationship18",
        "OccupierNamesAge18",
        "OccupierNamesName19",
        "OccupierNamesRelationship19",
        "OccupierNamesAge19",
        "OccupierNamesName20",
        "OccupierNamesRelationship20",
        "OccupierNamesAge20",
      ].includes(questionData.name)
    ) {
      let name = {
        OccupierNamesName: formValues?.OccupierNamesName || "",
        OccupierNamesRelationship:
          formValues?.OccupierNamesRelationship?.key || "",
        OccupierNamesAge: formValues?.OccupierNamesAge || "",
        OccupierNamesName2: formValues?.OccupierNamesName2 || "",
        OccupierNamesRelationship2:
          formValues?.OccupierNamesRelationship2?.key || "",
        OccupierNamesAge2: formValues?.OccupierNamesAge2 || "",
        OccupierNamesName3: formValues?.OccupierNamesName3 || "",
        OccupierNamesRelationship3:
          formValues?.OccupierNamesRelationship3?.key || "",
        OccupierNamesAge3: formValues?.OccupierNamesAge3 || "",
        OccupierNamesName4: formValues?.OccupierNamesName4 || "",
        OccupierNamesRelationship4:
          formValues?.OccupierNamesRelationship4?.key || "",
        OccupierNamesAge4: formValues?.OccupierNamesAge4 || "",
        OccupierNamesName5: formValues?.OccupierNamesName5 || "",
        OccupierNamesRelationship5:
          formValues?.OccupierNamesRelationship5?.key || "",
        OccupierNamesAge5: formValues?.OccupierNamesAge5 || "",
        OccupierNamesName6: formValues?.OccupierNamesName6 || "",
        OccupierNamesRelationship6:
          formValues?.OccupierNamesRelationship6?.key || "",
        OccupierNamesAge6: formValues?.OccupierNamesAge6 || "",
        OccupierNamesName7: formValues?.OccupierNamesName7 || "",
        OccupierNamesRelationship7:
          formValues?.OccupierNamesRelationship7?.key || "",
        OccupierNamesAge7: formValues?.OccupierNamesAge7 || "",
        OccupierNamesName8: formValues?.OccupierNamesName8 || "",
        OccupierNamesRelationship8:
          formValues?.OccupierNamesRelationship8?.key || "",
        OccupierNamesAge8: formValues?.OccupierNamesAge8 || "",
        OccupierNamesName9: formValues?.OccupierNamesName9 || "",
        OccupierNamesRelationship9:
          formValues?.OccupierNamesRelationship9?.key || "",
        OccupierNamesAge9: formValues?.OccupierNamesAge9 || "",
        OccupierNamesName10: formValues?.OccupierNamesName10 || "",
        OccupierNamesRelationship10:
          formValues?.OccupierNamesRelationship10?.key || "",
        OccupierNamesAge10: formValues?.OccupierNamesAge10 || "",

        OccupierNamesName11: formValues?.OccupierNamesName11 || "",
        OccupierNamesRelationship11:
          formValues?.OccupierNamesRelationship11?.key || "",
        OccupierNamesAge11: formValues?.OccupierNamesAge11 || "",
        OccupierNamesName12: formValues?.OccupierNamesName12 || "",
        OccupierNamesRelationship12:
          formValues?.OccupierNamesRelationship12?.key || "",
        OccupierNamesAge12: formValues?.OccupierNamesAge12 || "",
        OccupierNamesName13: formValues?.OccupierNamesName13 || "",
        OccupierNamesRelationship13:
          formValues?.OccupierNamesRelationship13?.key || "",
        OccupierNamesAge13: formValues?.OccupierNamesAge13 || "",
        OccupierNamesName14: formValues?.OccupierNamesName14 || "",
        OccupierNamesRelationship14:
          formValues?.OccupierNamesRelationship14?.key || "",
        OccupierNamesAge14: formValues?.OccupierNamesAge14 || "",
        OccupierNamesName15: formValues?.OccupierNamesName15 || "",
        OccupierNamesRelationship15:
          formValues?.OccupierNamesRelationship15?.key || "",
        OccupierNamesAge15: formValues?.OccupierNamesAge15 || "",
        OccupierNamesName16: formValues?.OccupierNamesName16 || "",
        OccupierNamesRelationship16:
          formValues?.OccupierNamesRelationship16?.key || "",
        OccupierNamesAge16: formValues?.OccupierNamesAge16 || "",
        OccupierNamesName17: formValues?.OccupierNamesName17 || "",
        OccupierNamesRelationship17:
          formValues?.OccupierNamesRelationship17?.key || "",
        OccupierNamesAge17: formValues?.OccupierNamesAge17 || "",
        OccupierNamesName18: formValues?.OccupierNamesName18 || "",
        OccupierNamesRelationship18:
          formValues?.OccupierNamesRelationship18?.key || "",
        OccupierNamesAge18: formValues?.OccupierNamesAge18 || "",
        OccupierNamesName19: formValues?.OccupierNamesName19 || "",
        OccupierNamesRelationship19:
          formValues?.OccupierNamesRelationship19?.key || "",
        OccupierNamesAge19: formValues?.OccupierNamesAge19 || "",
        OccupierNamesName20: formValues?.OccupierNamesName19 || "",
        OccupierNamesRelationship20:
          formValues?.OccupierNamesRelationship20?.key || "",
        OccupierNamesAge20: formValues?.OccupierNamesAge20 || "",
      };
      name[questionData?.name] = value || "";
      const fullString = [
        name?.OccupierNamesName,
        name?.OccupierNamesRelationship,
        name?.OccupierNamesAge,
        ",",
        name?.OccupierNamesName2,
        name?.OccupierNamesRelationship2,
        name?.OccupierNamesAge2,
        ",",
        name?.OccupierNamesName3,
        name?.OccupierNamesRelationship3,
        name?.OccupierNamesAge3,
        ",",
        name?.OccupierNamesName4,
        name?.OccupierNamesRelationship4,
        name?.OccupierNamesAge4,
        ",",
        name?.OccupierNamesName5,
        name?.OccupierNamesRelationship5,
        name?.OccupierNamesAge5,
        ",",
        name?.OccupierNamesName6,
        name?.OccupierNamesRelationship6,
        name?.OccupierNamesAge6,
        ",",
        name?.OccupierNamesName7,
        name?.OccupierNamesRelationship7,
        name?.OccupierNamesAge7,
        ",",
        name?.OccupierNamesName8,
        name?.OccupierNamesRelationship8,
        name?.OccupierNamesAge8,
        ",",
        name?.OccupierNamesName9,
        name?.OccupierNamesRelationship9,
        name?.OccupierNamesAge9,
        ",",
        name?.OccupierNamesName10,
        name?.OccupierNamesRelationship10,
        name?.OccupierNamesAge10,

        ",",
        name?.OccupierNamesName11,
        name?.OccupierNamesRelationship11,
        name?.OccupierNamesAge11,
        ",",
        name?.OccupierNamesName12,
        name?.OccupierNamesRelationship12,
        name?.OccupierNamesAge12,
        ",",
        name?.OccupierNamesName13,
        name?.OccupierNamesRelationship13,
        name?.OccupierNamesAge13,
        ",",
        name?.OccupierNamesName14,
        name?.OccupierNamesRelationship14,
        name?.OccupierNamesAge14,
        ",",
        name?.OccupierNamesName15,
        name?.OccupierNamesRelationship15,
        name?.OccupierNamesAge15,
        ",",
        name?.OccupierNamesName16,
        name?.OccupierNamesRelationship16,
        name?.OccupierNamesAge16,
        ",",
        name?.OccupierNamesName17,
        name?.OccupierNamesRelationship17,
        name?.OccupierNamesAge17,
        ",",
        name?.OccupierNamesName18,
        name?.OccupierNamesRelationship18,
        name?.OccupierNamesAge18,
        ",",
        name?.OccupierNamesName19,
        name?.OccupierNamesRelationship19,
        name?.OccupierNamesAge19,
        ",",
        name?.OccupierNamesName20,
        name?.OccupierNamesRelationship20,
        name?.OccupierNamesAge20,
      ]
        .join(" ")
        .trim();
      setFormValues((prevState) => ({
        ...prevState,
        OccupierNames: fullString,
      }));
    }

    checkValidation(questionData, value);
    setFormValues((prevState) => ({
      ...prevState,
      [questionData.name]: value?.key || value,
    }));
  };

  const checkValidation = (question, value) => {
    if (question.required) {
      if (multiRequired.includes(question.name) && value !== "") {
        let index = multiRequired.findIndex((i) => i === question.name);
        setMultiRequired([
          ...multiRequired.slice(0, index),
          ...multiRequired.slice(index + 1, multiRequired.length),
        ]);
      }
    }
  };

  const checkGoTo = (next, values) => {
    if (
      values !== undefined &&
      values[question.name] !== null &&
      values[question.name] !== undefined &&
      values[question.name] !== ""
    ) {
      let propertyName = question.name;
      if (question.questionId === 46) {
        propertyName = "AddingRemovingLegalOwnerValue";
      }
      let act = question.actions.find((a) => a.when === values[propertyName]);
      if (act?.type === actionType.GOTO) {
        next = act.where;
      }
    }

    if (user.reference !== null) {
      const refs = ["L29000", "H22500", "B32500"];
      const LBGClient = user.reference.split("/");
      if (question.questionId === 57 && refs.includes(LBGClient[0])) {
        next = question.skipTo;
      }
    }
    return next;
  };

  const handleContinue = (next, questionKey) => {
    if (question.questionId === 9) {
      if (props.mortgage.Mortgage.Borrowers.length < 2) {
        next = 10;
      }
    }
    if (
      question.questionId === 24 &&
      props.mortgage.Mortgage.RegMatterReference.indexOf("W1542") === -1
    ) {
      next = 26;
    }

    if (
      question.questionId === 54 &&
      props.mortgage.Mortgage.RegMatterReference.indexOf("W1542") === -1
    ) {
      next = 56;
    }
    if (
      question.questionType === questionType.OPTION ||
      question.questionType === questionType.BOOLEAN
    ) {
      next = checkGoTo(next, formValues);
    }

    var tempArray = questionTextArray
    // store the question text for the main question
    if (question.name) {
      let value = formValues?.[question.name]?.toString()
      tempArray = buildQuestion(value, question, tempArray);
    }

    if (question?.actions?.length) {
      for (const action of question.actions) {
        if (action.name) {
          let value = formValues?.[action.name]?.toString()
          tempArray = buildQuestion(value, action, tempArray);
        }

        // handle more info fields
        if (action.what) {
          let parentValue = formValues?.[question.name]
          // only run if the more info field should be shown
          if (parentValue === action.when) {
            let value = formValues?.[action.what.name]?.toString()
            tempArray = buildQuestion(value, action.what, tempArray);
          }
        }
        
      }
    }
    setQuestionTextArray(tempArray);
    
    if (next !== undefined) {
      checkContinue(next, formValues);
    }
  };

  const checkContinue = (next, values) => {

    if (question.questionType === questionType.MULTITEXT) {
      let hasError = false;
      let multiRequiredList = [];
      question.actions.forEach((element) => {
        if (element.required) {
          if (element.questionType === questionType.DATE) {
            let date = new Date(values[element.name]);

            // if it's a DOB field, the borrower needs to be over 18
            if (["Borrower1DOB", "Borrower2DOB"].includes(element.name)) {
              const dob = moment(date);
              const yearThreshold = moment().subtract(18, "years");
              if (dob.isSameOrAfter(yearThreshold)) {
                hasError = true;
                multiRequiredList.push(element.name);
              }
              return;
            }

            if (
              !isNaN(date) &&
              date.getYear() >= 0 &&
              date.getYear() <= 199 &&
              values[element.name] !== null
            ) {
              setIsRequired(false);
              canContinue(next);
            } else {
              hasError = true;
              multiRequiredList.push(element.name);
            }
          } else if (element.questionType === questionType.TEXT) {
            if (element.type === "email" || element.type === "phone") {
              var regex;

              if (element.type === "email") {
                regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              }
              if (element.type === "phone") {
                regex =
                  /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;
              }
              if (!regex.test(values[element.name])) {
                hasError = true;
                multiRequiredList.push(element.name);
              }
            } else {
              if (
                element.required &&
                (values === undefined ||
                  values[element.name] === null ||
                  values[element.name] === undefined ||
                  values[element.name] === "")
              ) {
                hasError = true;
                multiRequiredList.push(element.name);
              }
            }
          } else {
            if (
              element.required &&
              (values === undefined ||
                values[element.name] === null ||
                values[element.name] === undefined ||
                values[element.name] === "")
            ) {
              hasError = true;
              multiRequiredList.push(element.name);
            }
          }
        } else if (question.questionId === 9) {
          if (
            [undefined, null, ""].includes(values.Borrower1PassportNo) &&
            [undefined, null, ""].includes(values.Borrower1DrivingLicenceNo)
          ) {
            hasError = true;
            multiRequiredList.push(element.name);
          }
        } else if (question.questionId === 102) {
          if (
            [undefined, null, ""].includes(values.Borrower2PassportNo) &&
            [undefined, null, ""].includes(values.Borrower2DrivingLicenceNo)
          ) {
            hasError = true;
            multiRequiredList.push(element.name);
          }
        }
      });
      if (hasError) {
        setIsRequired(true);
        setMultiRequired(multiRequiredList);
      } else {
        setIsRequired(false);
        canContinue(next);
      }
    } else if (question?.actions?.length) {
      let hasError = false;
      let multiRequiredList = [];
      question.actions.forEach((element) => {
        if (element.required) {
          let elementName = element.what.name;
          if (element.questionType === questionType.DATE) {
            let date = new Date(values[elementName]);

            // if it's a DOB field, the borrower needs to be over 18
            if (["Borrower1DOB", "Borrower2DOB"].includes(elementName)) {
              const dob = moment(date);
              const yearThreshold = moment().subtract(18, "years");
              if (dob.isSameOrAfter(yearThreshold)) {
                hasError = true;
                multiRequiredList.push(elementName);
              }
              return;
            }

            if (
              !isNaN(date) &&
              date.getYear() >= 0 &&
              date.getYear() <= 199 &&
              values[elementName] !== null
            ) {
              setIsRequired(false);
              canContinue(next);
            } else {
              hasError = true;
              multiRequiredList.push(elementName);
            }
          } else if (element.questionType === questionType.TEXT) {
            if (element.type === "email" || element.type === "phone") {
              var regex;

              if (element.type === "email") {
                regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              }
              if (element.type === "phone") {
                regex =
                  /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;
              }
              if (!regex.test(values[elementName])) {
                hasError = true;
                multiRequiredList.push(elementName);
              }
            } else {
              if (
                element.required &&
                (values === undefined ||
                  values[elementName] === null ||
                  values[elementName] === undefined ||
                  values[elementName] === "")
              ) {
                hasError = true;
                multiRequiredList.push(elementName);
              }
            }
          } else {
            if (
              element.required &&
              (values === undefined ||
                values[elementName] === null ||
                values[elementName] === undefined ||
                values[elementName] === "")
            ) {
              hasError = true;
              multiRequiredList.push(elementName);
            }
          }
        }
      });
      if (hasError) {
        setIsRequired(true);
        // stop here
        return
      }

      // use the name to get the parent
      const matchingField = question.actions.find(i => i.when === values[question.name])
      if (matchingField?.what?.name) {
        const moreInfoName = matchingField?.what?.name
        if (
          values === undefined ||
          values[moreInfoName] === null ||
          values[moreInfoName] === undefined ||
          values[moreInfoName] === ""
        ) {
          setIsRequired(true);
        } else {
          setIsRequired(false);
          canContinue(next);
        }

        // stop here
        return
      }

      // allow them through
      setIsRequired(false);
      canContinue(next);

    } else if (question.questionType === questionType.CHECKBOX) {
      if (question.required) {
        if (
          values === undefined ||
          values[question.name] === null ||
          values[question.name] === undefined ||
          values[question.name] === false
        ) {
          setIsRequired(true);
        } else {
          setIsRequired(false);
          canContinue(next);
        }
      } else {
        setIsRequired(false);
        canContinue(next);
      }
    } else if (question.questionType === questionType.DATE) {
      if (question.required) {
        let date = new Date(values[question.name]);
        if (!isNaN(date) && date.getYear() >= 0 && date.getYear() <= 199) {
          setIsRequired(false);
          canContinue(next);
        } else {
          setIsRequired(true);
        }
      } else {
        setIsRequired(false);
        canContinue(next);
      }
    } else if (question.questionType === questionType.TEXT) {
      if (question.type === "email" || question.type === "phone") {
        var regex;

        if (question.type === "email") {
          regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        }
        if (question.type === "phone") {
          regex =
            /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;
        }

        if (!regex.test(values[question.name])) {
          setIsRequired(true);
        } else {
          setIsRequired(false);
          canContinue(next);
        }
      } else {
        if (question.required) {
          if (
            values === undefined ||
            values[question.name] === null ||
            values[question.name] === undefined ||
            values[question.name] === ""
          ) {
            setIsRequired(true);
          } else {
            setIsRequired(false);
            canContinue(next);
          }
        } else {
          canContinue(next);
        }
      }
    } else {
      if (question.required) {
        if (question.questionId === 46) {
          if (
            values === undefined ||
            values["AddingRemovingLegalOwnerValue"] === null ||
            values["AddingRemovingLegalOwnerValue"] === undefined ||
            values["AddingRemovingLegalOwnerValue"] === ""
          ) {
            setIsRequired(true);
          } else {
            setIsRequired(false);
            canContinue(next);
          }
        } else {
          if (
            values === undefined ||
            values[question.name] === null ||
            values[question.name] === undefined ||
            values[question.name] === ""
          ) {
            setIsRequired(true);
          } else {
            setIsRequired(false);
            canContinue(next);
          }
        }
      } else {
        canContinue(next);
      }
    }
  };

  const canContinue = (next) => {
    var data = {};

    data.MatterReference = props.mortgage.MatterReference;
    data.EntityReference =
      props.mortgage.Mortgage.MatterAdminDetails.EntityReference;
    data.MatterNumber = props.mortgage.Mortgage.MatterAdminDetails.MatterNumber;

    let extraQuestions = {};

    for (var key in formValues) {
      if (questionnaire.extra_questions.includes(key)) {
        extraQuestions[key] = formValues[key];
      } else {
        data[key] = formValues[key];
      }
    }

    data.extraJSON = extraQuestions;
    data.questionText = questionTextArray;

    const config = {
      headers: { Authorization: token },
    };
    const params = {
      orgId: org.id,
      siteId: null,
    };

    if (questionnaireId) {
      WebServiceClient.patch(
        `/questionaire/${questionnaireId}`,
        data,
        params,
        config
      )
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    } else {
      WebServiceClient.post("/questionaire", data, params, config)
        .then(function (response) {
          setQuestionnaireId(response.data.id);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (next !== undefined && next !== "SubmitPage") {
      setQuestionStack((oldArray) => [...oldArray, question.questionId]);
      getIndexForProgressBar(next, false);
      let nextQ = questionnaire.questions.find((q) => q.questionId === next);
      getFormValues(nextQ, formValues);
      setCurrentIndex(next);
      setMoreInfo();
      setIsRequired(false);
    } else {
      window.alert('stopping here')
      return
      data.QuestionnaireCompleteInP4W = true;

      ReactGA.event({
        category: "Questionnaire",
        action: "Complete",
      });

      WebServiceClient.post(
        `/questionaire/${questionnaireId}/complete`,
        data,
        params,
        config
      )
        .then(function (response) {
          props.setDisableQuestionnaire(true);
        })
        .catch(function (error) {
          console.log(error);
        });
      setSubmitSuccess(true);
      setFormValues();
      setDefaultValues();
      setQuestionIndex(1);
    }
  };

  const getIndexForProgressBar = (next, isBack) => {
    let nextQuestionIndex = questionnaire.questions.findIndex(
      (q) => q.questionId === next
    );

    let currentQuestionIndex = questionnaire.questions.findIndex(
      (q) => q.questionId === question.questionId
    );
    setQuestionIndex(nextQuestionIndex + 1);
    let step = 100 / totalQuestions;
    let jump = Math.abs(nextQuestionIndex - currentQuestionIndex);
    setProgress(isBack ? progress - step * jump : progress + step * jump);
  };

  const getFormValues = (question, values) => {
    let obj = {};
    if (question?.questionType === questionType.MULTITEXT) {
      question.actions.forEach((e) => {
        fillWithDefault(e, obj, values);
      });
    } else {
      fillWithDefault(question, obj, values);
    }
    setFormValuesMulti(obj);
  };

  const fillWithDefault = (question, obj, values) => {
    if (!question?.name) return;

    if (
      values &&
      values[question.name] !== undefined &&
      values[question.name] !== null &&
      values[question.name] !== ""
    ) {
      obj[question.name] = values[question.name];
    } else {
      if (question.questionType === questionType.TEXT) {
        obj[question.name] = "";
      }
      if (
        question.questionType === questionType.BOOLEAN ||
        question.questionType === questionType.OPTION
      ) {
        obj[question.name] = -1;
      }
      if (question.questionType === questionType.SELECT) {
        obj[question.name] = question.actions[0].key;
      }
    }
  };

  const handleBack = () => {
    let stack = [...questionStack];
    if (stack[stack.length - 1]) {
      let previousQ = questionnaire.questions.find(
        (q) => q.questionId === stack[stack.length - 1]
      );

      getIndexForProgressBar(stack[stack.length - 1], true);
      getFormValues(previousQ, formValues);
      setCurrentIndex(stack[stack.length - 1]);
    }
    stack.pop();
    setQuestionStack(stack);
    setMultiRequired([]);
  };

  return (
    <>
      {!submitSuccess ? (
        <form>
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              color="progress"
            />
          </Box>
          <Typography
            variant="h6"
            style={{ paddingLeft: "0px", fontSize: "12px", paddingTop: "3px" }}
          >
            {questionIndex} of {totalQuestions} questions
          </Typography>
          <Typography sx={style.questionHeader}>{question.section}</Typography>
          {isRequired ? (
            <Typography style={style.questionError}>
              {question.error}
            </Typography>
          ) : null}
          <Box direction="column" name={question.name}>
            <Typography sx={style.question}>
              {question.labelText ? question.labelText : question.questionText}{" "}
            </Typography>
            <Box direction="row">{getType(question)}</Box>
            <Box direction="row" p={2}>
              {moreInfo ? <div>{getMoreInfo()}</div> : null}
            </Box>
            <Box>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Button
                  sx={style.questionBackButton}
                  onClick={() => {
                    handleBack();
                  }}
                >
                  {questionnaire.back_button_label}
                </Button>
                <Button
                  sx={style.questionNavigationButton}
                  onClick={() => {
                    handleContinue(question.next);
                  }}
                >
                  {questionIndex === totalQuestions
                    ? "Submit"
                    : questionnaire.continue_button_label}
                </Button>
              </Stack>
            </Box>
          </Box>
        </form>
      ) : (
        <SuccessPage />
      )}
    </>
  );
}

export default Questions;
