import { LocalStorageService } from "./LocalStorageService";
import { WebServiceClient, setAccessToken } from "./WebServiceClient";

export class SignupService {
  static login(username, password) {
    const requestUrl = "/authentication/login";
    const config = {};
    const data = {
      username,
      password,
    };

    /*(async () => {
      try {
        const result = await WebServiceClient.post(requestUrl, data, config);
        console.log("result====>", result);
      } catch (e) {
        console.log(e); // 401
      }
    })();*/

    WebServiceClient.post(requestUrl, data, config, {})
      .then(function (response) {
        setAccessToken(response.data.token)
        LocalStorageService.set("user", response.data.user);
        LocalStorageService.set(
          "organisation",
          response.data.userOrganisations[0]
        );
        return response.data;
      })
      .catch(function (error) {
        console.log(error.response.status); // 401
        //console.log(error.response.data.error); //Please Authenticate or whatever returned from server
      });
  }

  static async logout() {
    const requestUrl = "/authentication/logout";
    try {
      await WebServiceClient.get(requestUrl, {}, {}, true)
    } catch (err) { }
  }

  static async refresh() {
    const requestUrl = "/authentication/refresh";
    try {
      let response = await WebServiceClient.get(requestUrl, {}, {}, true)
      setAccessToken(response.data.token)
      return true
    } catch (err) {
      return false
    }
  }

  static activate(matterReference, postcode, username, password) {
    const requestUrl = "/user/invite";
    const config = {};
    const data = {
      matterReference,
      postcode,
      username,
      password,
    };
  }
}
