import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

// components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { CardMedia } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Alerts from "../shared/Alerts";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import InputAdornment from "@mui/material/InputAdornment";

// style
import { style } from "../../style/style";
import { styled } from "@mui/material/styles";

// data
import { SignupUtils } from "../../utils/SignupUtils";
import { SignupType } from "../../enums/SignupType";
import { activate_schema } from "../../config/validation";
import { signup } from "../../resources/signup-data";

// services
import { WebServiceClient } from "../../services/WebServiceClient";
import { color } from "../../style/color";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingTop: "0px",
    "& input": {
      color: "white",
      "&::placeholder": {
        color: "white",
        textAlign: "center",
      },
    },
    "& fieldset": {
      borderColor: "white",
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

export default function Password(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);

  const [signupData, setSignupData] = useState(
    SignupUtils.getSignupData(SignupType.ACTIVATE)
  );

  const onSubmit = (values) => {
    const data = {
      password: values.password,
    };

    WebServiceClient.post("/knights-auth/register-borrower", data, {}, {})
      .then(function (response) {
        navigate("/login", {
          state: {
            password: values.password,
            alert: {
              open: true,
              severity: "success",
              message: signup.message.activate,
              action: false,
            },
          },
        });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          navigate("/login", {
            state: {
              password: values.password,
              alert: {
                open: true,
                severity: "error",
                message: signup.errors.invalid_credentials,
                action: false,
              },
            },
          });
        } else {
          setAlert({
            open: true,
            severity: "error",
            message: signup.errors.error_activate,
            action: false,
          });
        }
      });
  };

  const onClickLink = (values) => {
    navigate("/login");
  };

  const handleTermsClick = (e) => {
    window.open(
      "https://www.knightsplc.com/our-policies/acceptable-use-policy/",
      "_blank"
    );
  };

  return (
    <Grid item xs={12}>
      {loading ? (
        <CircularProgress
          size={68}
          sx={{
            color: "white",
            position: "absolute",
            margin: "auto",
            zIndex: 1,
          }}
        />
      ) : (
        <Card sx={style.signup} elevation={0}>
          <CardMedia style={style.logo} alt="Integrar Logo" />
          <CardHeader
            title=""
            subheader={signupData.subTitle}
            titleTypographyProps={{ variant: "h2" }}
            subheaderTypographyProps={{
              variant: "h5",
              color: "secondary.main",
            }}
          />
          <Formik
            validationSchema={activate_schema}
            initialValues={{
              password: "",
            }}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <CardContent>
                  {signupData.description !== "" ? (
                    <Typography variant="caption">
                      {signupData.description}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <CssTextField
                    type="password"
                    name="password"
                    placeholder={signupData.password_label}
                    sx={style.cssTextField}
                    value={values.password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Your password should be a least 8 characters, include a mixture of uppercase and lowercase letters, at least one number and at least one character in !@#$%^&*()-_+=">
                            <IconButton>
                              <HelpIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Card
                    className="error"
                    sx={
                      errors.password && touched.password && errors.password
                        ? style.error
                        : ""
                    }
                  >
                    {errors.password && touched.password && errors.password}
                  </Card>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange}
                        name="terms"
                        value={values.terms}
                        color="secondary"
                        sx={{
                          color: "white",
                        }}
                      />
                    }
                    label={
                      <span
                        onClick={() => handleTermsClick()}
                        style={{
                          textDecoration: "underline",
                          color: color.smoke,
                        }}
                      >
                        {signupData.terms_condition_label}
                      </span>
                    }
                  />
                  <Card
                    className="error"
                    sx={
                      errors.terms && touched.terms && errors.terms
                        ? style.error
                        : ""
                    }
                  >
                    {errors.terms && touched.terms && errors.terms}
                  </Card>
                  <CardActions
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="button"
                      sx={{ width: "350px", color: "white" }}
                    >
                      {signupData.submit_label}
                    </Button>
                  </CardActions>
                </CardContent>

                <CardContent>
                  <Box direction="row">
                    <Link
                      style={{ cursor: "pointer" }}
                      underline="hover"
                      variant="caption2"
                      color="secondary.main"
                      onClick={() => onClickLink()}
                    >
                      {signupData.link_label}
                    </Link>
                  </Box>
                </CardContent>
              </form>
            )}
          </Formik>
        </Card>
      )}
      <Alerts alert={alert} setAlert={setAlert} />
    </Grid>
  );
}
