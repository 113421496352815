import { useNavigate } from "react-router-dom";

// mui
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

// style
import { style } from "../../style/style";

// data
//services
import { Button, CardMedia } from "@mui/material";
import { LocalStorageService } from "../../services/LocalStorageService";
import { useEffect } from "react";

export default function Landing() {
  const navigate = useNavigate();
  return (
    <Grid item xs={12}>
      <Card sx={style.signup} elevation={0}>
        <CardMedia style={style.logo} alt="Integrar Logo" />
        <CardHeader
          title=""
          subheader={"Select an option to continue"}
          titleTypographyProps={{ variant: "h2" }}
          subheaderTypographyProps={{
            variant: "h5",
            color: "secondary.main",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
          }}
        >
          <Button
            variant="contained"
            color="button"
            sx={{
              width: "350px",
              color: "white",
              marginBottom: "0px",
            }}
            onClick={() => navigate("/login")}
          >
            I already have an account
          </Button>
          <p style={{ color: "white" }}>or</p>
          <Button
            variant="contained"
            color="button"
            sx={{
              width: "350px",
              color: "white",
              marginTop: "0px",
              marginBottom: "60px",
            }}
            onClick={() => navigate("/activate")}
          >
            I need to activate a new matter
          </Button>
        </div>
      </Card>
    </Grid>
  );
}
