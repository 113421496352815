export class LocalStorageService {
  static get(key) {
    try {
      var value = localStorage.getItem(key);

      // somewhere undefined is being stored as "undefined"
      // if that happens, make it actually undefined
      if (value === "undefined") {
        value = undefined;
      }

      return value;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static set(key, value) {
    try {
      if (typeof value === "object") {
        value = JSON.stringify(value);
      }
      localStorage.setItem(key, value);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static clear() {
    localStorage.clear();
  }
}
