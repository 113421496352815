import { useEffect, useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import SnackbarContent from "@mui/material/SnackbarContent";

export default function Alerts(props) {
  const navigate = useNavigate();

  const [vertical, setVertical] = useState("top");
  const [horizontal, setHorizontal] = useState("center");

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const action = (
    <Button color="secondary" size="small" onClick={() => navigate("/reset")}>
      RESET
    </Button>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setAlert({
      open: false,
      severity: "",
      message: "",
      action: false,
    });
  };

  return (
    <Snackbar
      open={props.alert.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert
        action={props.alert.action ? action : ""}
        onClose={handleClose}
        severity={props.alert.severity}
        sx={{ width: "100%" }}
      >
        {props.alert.message}
      </Alert>
    </Snackbar>
  );
}
