import React, { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// services
import {
  getAccessToken,
  WebServiceClient,
} from "../../services/WebServiceClient";
import { LocalStorageService } from "../../services/LocalStorageService";

import { form } from "../../resources/form-data";
import { color } from "../../style/color";
import { FileUploader } from "react-drag-drop-files";

function DragDropFileDialog(props) {
  const token = getAccessToken();
  const org = JSON.parse(LocalStorageService.get("organisation"));

  const [fileSelected, setFileSelected] = useState();
  const [error, setError] = useState(null);

  const checkFile = (file) => {
    let extensions = ["pdf", "jpeg", "png", "gif"];
    var type = file.type.split("/")[1];
    if (extensions.includes(type)) {
      setFileSelected(file);
      setError(null);
    } else {
      setFileSelected(null);
      setError("only jpg, pdf, png and gif");
    }
  };

  const uploadDocument = () => {
    const config = {
      headers: { Authorization: token },
    };
    const formData = new FormData();

    formData.append("file", fileSelected);

    formData.append("matterReference", props.mortgage.MatterReference);

    formData.append("category", props.documentSelected);
    if (props.documentSelected === "OTH") {
      formData.append("other", props.documentType);
    }

    const params = {
      orgId: org.id,
      siteId: null,
    };

    WebServiceClient.post("/knights-fileupload", formData, params, config)
      .then(function (response) {
        console.log(response);
        props.handleClose();
        props.setRecords([
          ...props.records,
          {
            type: response.data.records[0].description,
            date: response.data.records[0].createdAt,
            url: response.data.records[0].fileUrl,
          },
        ]);
        props.setExpanded("panel2");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <FileUploader
        handleChange={checkFile}
        dropMessageStyle={{ color: "#fff" }}
        hoverTitle=""
        name="file"
        types={["JPG", "PNG", "GIF", "PDF"]}
      >
        <Box
          style={{
            border: "3px dashed rgb(64, 142, 208)",
            padding: "10px",
          }}
        >
          <Box style={{ paddingBottom: "40px" }}>
            <Typography style={{ color: "black" }}>
              Drag and drop a file to upload
            </Typography>
            <Typography style={{ color: "black" }}>
              <div
                style={{
                  fontSize: ".7em",
                  textAlign: "center",
                }}
              >
                We support the following file types: “jpg, png, gif, pdf
              </div>
            </Typography>
          </Box>

          <label
            htmlFor="file_upload"
            style={{
              backgroundColor: "gray",
              color: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            Select a file
          </label>
          <input
            id="file_upload"
            type="file"
            onChange={(ev) => {
              checkFile(ev.target.files[0]);
            }}
            style={{ display: "none" }}
          />
          {fileSelected && (
            <Box style={{ marginTop: 20 }}>
              {fileSelected ? fileSelected.name : null}
            </Box>
          )}
          {error && <Box style={{ marginTop: 20, color: "red" }}>{error}</Box>}
        </Box>
      </FileUploader>

      <div style={{ padding: "20px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Button onClick={() => props.handleClose()}>
            {" "}
            {form.upload_dialog.button_cancel_label}
          </Button>
          <Button onClick={() => props.setIsUpload(false)} variant="contained">
            {"<<"}Back
          </Button>
          <Button
            onClick={() => uploadDocument()}
            variant="contained"
            style={{ backgroundColor: color.brandDarkBlue }}
          >
            {form.upload_dialog.button_upload_label}
          </Button>
        </Stack>
      </div>
    </>
  );
}

export default DragDropFileDialog;
