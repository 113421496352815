import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// mui
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

// style
import { style } from "../../style/style";

// services
import { LocalStorageService } from "../../services/LocalStorageService";
import { WebServiceClient, getAccessToken, setAccessToken } from "../../services/WebServiceClient";
import Sidebar from "./sidebar/Sidebar";
import { SignupService } from "../../services/SignupService";

// components
import Remortgage from "./form/Remortgage";
import MakeAPayment from "./form/MakeAPayment";
import HelpSupport from "./form/HelpSupport";
import YourInformation from "./form/YourInformation";
import YourDocuments from "./form/YourDocuments";

import { makeStyles } from "@material-ui/core";
// setting
import { defaultValues } from "../../config/setting";
import { form } from "../../resources/form-data";

import AppContext from "../../AppContext";
import MatterReferenceDialog from "../dialogs/MatterReferenceDialog";
import { TimeoutLogicDialog } from "../dialogs/TimeoutLogicDialog";

export default function Dashboard(props) {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const user = JSON.parse(LocalStorageService.get("user"));
  const org = JSON.parse(LocalStorageService.get("organisation"));
  const matterRef = LocalStorageService.get("matterRef");
  const mortgageStorage = JSON.parse(LocalStorageService.get("mortgage"));
  const mortgagesStorage = JSON.parse(LocalStorageService.get("mortgages"));

  const [mortgage, setMortgage] = useState("");
  const [mortgages, setMortgages] = useState([]);
  const [tab, setTab] = useState(defaultValues.defaultTab);
  const [openDialog, setOpenDialog] = useState(false);
  const [matter, setMatter] = useState();

  const getTitle = (key) => {
    return form.tabs.find((t) => t.key === key).title;
  };

  const getColor = (key) => {
    return form.tabs.find((t) => t.key === key).color;
  };

  useEffect(() => {
    // Helper to get mortgage info and set good data
    let getMortgageInfo = async () => {
      const config = {
        headers: { Authorization: getAccessToken() },
      };
      const params = {
        orgId: org.id,
        siteId: null,
      };

      try {
        let response = await WebServiceClient.get("/mortgage/borrowers/active", params, config)

        console.log('we are here 74')
        if (response.data) {
          setMortgages(response.data);
          LocalStorageService.set("mortgages", response.data);
          setMatter(response.data[0].MatterReference);
          setMortgage(response.data[0]);
          if (response.data.length > 1) {
            setOpenDialog(true);
          }
          setLoading(false)
        }
      } catch (err) {
        console.log(err)
      }
    }

    const init = async () => {
      if (!getAccessToken() && !location?.state?.token) {
        if (!await SignupService.refresh()) {
          navigate("/landing");
        }
      } else {
        if (!getAccessToken()) {
          setAccessToken(location?.state?.token)
        }
      }

      // Make sure we go back to the previous matter ref
      if (matterRef && mortgagesStorage) {
        setMortgages(mortgagesStorage);
        setMatter(matterRef);
        setMortgage(mortgageStorage);
        setLoading(false)
      } else {
        await getMortgageInfo()
      }

    }

    init()
  }, []);

  const goBack = () => {
    let stack = [...appContext.stack];
    stack.pop();
    setTab(stack[stack.length - 1]);
    appContext.setStack(stack);
  };

  const getTabContent = () => {
    switch (tab) {
      case 1:
        return (
          <Remortgage
            mortgages={mortgages}
            mortgage={mortgage}
            matter={matter}
          />
        );
      case 3:
        return <YourDocuments mortgages={mortgages} mortgage={mortgage} />;
      case 4:
        return <YourInformation mortgages={mortgages} mortgage={mortgage} />;
      case 5:
        return <HelpSupport mortgages={mortgages} mortgage={mortgage} />;
      case 6:
        return <MakeAPayment mortgages={mortgages} mortgage={mortgage} />;
      default:
        break;
    }
  };
  return (
    <Grid container spacing={2} sx={style.mortgageDashboard}>
      {loading ? (
        <CircularProgress
          size={68}
          sx={{
            color: "white",
            position: "absolute",
            margin: "auto",
            zIndex: 1,
          }}
        />
      ) : (
        <>
          <Grid item md={4} lg={4} xl={4}>
            <Paper style={{ borderRadius: "10px" }}>
              <Sidebar
                tab={tab}
                getTitle={getTitle}
                setTab={setTab}
                mortgages={mortgages}
                setMortgage={setMortgage}
                mortgage={mortgage}
              />
            </Paper>
          </Grid>
          <TimeoutLogicDialog />
          <MatterReferenceDialog
            matter={matter}
            setMatter={setMatter}
            open={openDialog}
            setOpen={setOpenDialog}
            mortgage={mortgage}
            mortgages={mortgages}
            setMortgage={setMortgage}
          />
          <Grid item md={8} lg={8} xl={8} sm={12} xs={12}>
            <Paper
              style={{
                backgroundColor: getColor(tab),
                padding: "5px",
                borderRadius: "10px",
                //height: "650px",
              }}
            >
              <Stack direction="row">
                {/* TODO: add again on certain pages in the next phase of dev 
            {[4, 5, 6].includes(tab) ? (
              <IconButton
                aria-label="back button"
                size="small"
                sx={style.backButton}
                onClick={() => goBack()}
              >
                <ArrowBackIosIcon fontSize="inherit" /> {form.back_button_label}
              </IconButton>
            ) : null} */}

                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  siteId
                  alignItems="flex-start"
                  spacing={0}
                >
                  <Typography variant="title">{getTitle(tab)}</Typography>
                  <Typography
                    variant="subtitle"
                    className="breadcrumb"
                    sx={style.breadcrumb}
                  >
                    {form.shared.path} {getTitle(tab)}
                  </Typography>
                </Stack>
              </Stack>
              {getTabContent()}
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}
