import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

// mui
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import StepContent from "@mui/material/StepContent";
import { Button } from "@mui/material";

// data
import { milestonesData } from "../../../resources/milestone-data";
import { displayDate } from "../../../config/setting";
import { color } from "../../../style/color";
import { style } from "../../../style/style";
import { styled } from "@mui/material/styles";

// components
import Questions from "./questionnarie/Questions";

// utils
import { RemortgageUtils } from "../../../utils/RemortgageUtils";

// services
import { LocalStorageService } from "../../../services/LocalStorageService";
import { WebServiceClient, getAccessToken } from "../../../services/WebServiceClient";

const CssStep = styled(Step)({
  ".MuiStepConnector-root": {
    minHeight: "10px",
    "& .MuiStepConnector-line": {
      minHeight: "10px",
    },
  },
});

export default function Remortgage(props) {
  const mil = Array(milestonesData.length).fill(false);
  const matterRef = LocalStorageService.get("matterRef");
  const org = JSON.parse(LocalStorageService.get("organisation"));
  const [openQuestionnarie, setOpenQuestionnarie] = useState(false);
  const [isHovering, setIsHovering] = useState();
  const [indexes, setIndexes] = useState(mil);
  const [disableQuestionnaire, setDisableQuestionnaire] = useState(false);
  const [isQuestionnaireComplete, setIsQuestionnaireComplete] = useState(false);
  const [milestones, setMilestones] = useState(milestonesData);

  const isComplete = (key) => {
    let completedAt = "";
    if (
      (key === "QuestionnaireReceived" && isQuestionnaireComplete) ||
      (key === "QuestionnaireReceived" &&
        props.mortgage.Mortgage &&
        props.mortgage.Mortgage.Questionaire &&
        props.mortgage.Mortgage.Questionaire.Completed)
    ) {
      return true;
    }
    if (
      props.mortgage.Mortgage &&
      props.mortgage.Mortgage.Milestones &&
      props.mortgage.Mortgage.Milestones.milestones
    ) {
      if (
        props.mortgage.Mortgage.Milestones.milestones.find(
          (m) => m.name === key
        )
      ) {
        completedAt = props.mortgage.Mortgage.Milestones.milestones.find(
          (m) => m.name === key
        ).completedAt;
      }
    }
    return completedAt !== undefined && completedAt !== "";
  };

  const getMilestoneContent = (key, isComplete) => {
    switch (key) {
      case "QuestionnaireReceived":
        return (
          <>
            <p style={{ fontSize: "12px", margin: "0" }}>
              {getMilestoneDescription(key, isComplete)}
            </p>
            {!isComplete && !disableQuestionnaire ? (
              <Button
                variant="contained"
                style={{ marginTop: "10px" }}
                sx={style.darkBlueButton}
                onClick={() => setOpenQuestionnarie(true)}
              >
                Complete Questionnaire
              </Button>
            ) : null}
          </>
        );

      default:
        return (
          <p style={{ fontSize: "12px", margin: "0" }}>
            {getMilestoneDescription(key)}
          </p>
        );
    }
  };

  const getMilestoneDescription = (key) => {
    let content = isComplete(key)
      ? milestones.find((m) => m.key === key).complete
      : milestones.find((m) => m.key === key).incomplete;

    if (key === "PreCompletionSetup" && isComplete(key)) {
      let date = props.mortgage.Mortgage.Milestones.milestones.find(
        (m) => m.name === key
      ).completedAt;
      content = content.replace("{date}", displayDate(date));
    }

    if (
      key === "QuestionnaireReceived" &&
      props?.mortgage?.Mortgage?.MatterAdminDetails?.IsLms
    ) {
      content = "Please complete the questionnaire provided to you by LMS.";
    }

    return content;
  };
  const completeStepIcon = (props) => {
    return <CheckCircleIcon color="complete" />;
  };
  const incompleteStepIcon = (props) => {
    return <ErrorIcon color="incomplete" />;
  };

  const handleOpenStep = (index) => {
    let value = indexes[index];
    setIndexes([
      ...indexes.slice(0, index),
      !value,
      ...indexes.slice(index + 1, indexes.length),
    ]);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/remortgage" });
  }, []);

  useEffect(() => {
    const config = {
      headers: { Authorization: getAccessToken() },
    };
    const params = {
      orgId: org.id,
      siteId: null,
    };

    WebServiceClient.get("/mortgage/borrowers/active", params, config)
      .then(function (response) {
        if (response.data) {
          let questionaire = response.data.find(
            (q) => q.MatterReference === matterRef
          );

          if (questionaire && questionaire.Mortgage) {
            if (
              (questionaire.Mortgage.MatterAdminDetails &&
                questionaire.Mortgage.MatterAdminDetails.IsLms) ||
              (questionaire.Mortgage.Questionaire &&
                questionaire.Mortgage.Questionaire.QuestionnaireCompleteInP4W)
            ) {
              setDisableQuestionnaire(true);
            }
            if (
              questionaire.Mortgage.Questionaire &&
              questionaire.Mortgage.Questionaire.Completed
            ) {
              setIsQuestionnaireComplete(true);

              for (let i = 0; i < milestones.length; i++) {
                if (!isComplete(milestones[i].key)) {
                  setIndexes([
                    ...mil.slice(0, i),
                    true,
                    ...mil.slice(i + 1, mil.length),
                  ]);
                  break;
                }
              }
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const getMilestonesOpen = () => {
    for (let i = 0; i < milestones.length; i++) {
      let isCompleted = isComplete(milestones[i].key);
      if (!isCompleted) {
        setIndexes([...mil.slice(0, i), true, ...mil.slice(i + 1, mil.length)]);
        break;
      }
    }
  };

  const checkStatus = () => {
    if (
      (props.mortgage.Mortgage.MatterAdminDetails &&
        props.mortgage.Mortgage.MatterAdminDetails.IsLms) ||
      (props.mortgage.Mortgage.Questionaire &&
        props.mortgage.Mortgage.Questionaire.QuestionnaireCompleteInP4W)
    ) {
      setDisableQuestionnaire(true);
    }

    if (
      (props.mortgage.Mortgage.MatterAdminDetails &&
        !props.mortgage.Mortgage.MatterAdminDetails.IsLms) ||
      (props.mortgage.Mortgage.Questionaire &&
        !props.mortgage.Mortgage.Questionaire.QuestionnaireCompleteInP4W)
    ) {
      setDisableQuestionnaire(false);
    }

    if (
      props.mortgage.Mortgage.Questionaire &&
      props.mortgage.Mortgage.Questionaire.Completed
    ) {
      setIsQuestionnaireComplete(true);
    } else {
      setIsQuestionnaireComplete(false);
    }
    getMilestonesOpen();
  };

  useEffect(() => {
    if (props.mortgage.Mortgage) {
      setIsQuestionnaireComplete(false);
      getMilestones();
      checkStatus();
    }
  }, [props.mortgage.Mortgage]);

  const getMilestones = () => {
    return milestones?.map((item, index) => {
      return (
        <CssStep
          active={indexes[index]}
          key={index}
          onClick={() => handleOpenStep(index)}
        >
          <StepLabel
            StepIconComponent={
              isComplete(item.key) ? completeStepIcon : incompleteStepIcon
            }
            style={{ padding: "0px" }}
          >
            <Typography
              variant="body2"
              style={{
                color: isComplete(item.key) ? color.brandOlive : color.red,
                transition: "all .2s ease-out 100ms",
                fontSize: isHovering === index ? "17px" : "",
              }}
              onMouseEnter={() => setIsHovering(index)}
              onMouseLeave={() => setIsHovering(-1)}
            >
              {item.name}
            </Typography>
          </StepLabel>
          <StepContent style={style.step}>
            <p style={{ fontSize: "12px", margin: "0" }}>
              {getMilestoneContent(item.key, isQuestionnaireComplete)}
            </p>
          </StepContent>
        </CssStep>
      );
    });
  };
  return (
    <Paper style={style.remortgage}>
      <Grid container>
        {openQuestionnarie ? (
          <Grid item xs={12} style={{ padding: "15px" }}>
            <Questions
              mortgage={props.mortgage}
              setDisableQuestionnaire={setDisableQuestionnaire}
              setIsQuestionnaireComplete={setIsQuestionnaireComplete}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
              >
                {props.mortgage.Mortgage && (
                  <Typography variant="h6">
                    MATTER REFERENCE:{" "}
                    {props.mortgage.Mortgage.RegMatterReference}
                  </Typography>
                )}

                {props.mortgage.Mortgage && (
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Typography variant="h6">
                      {
                        props.mortgage.Mortgage.PropertyDetails
                          .RemortgageAddressLine1
                      }
                    </Typography>
                    <Typography variant="h6">
                      {RemortgageUtils.getBorrowers(
                        props.mortgage.Mortgage.Borrowers
                      )}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} style={{ padding: "15px" }}>
              {props.mortgage.Mortgage && (
                <>
                  <Stepper orientation="vertical">{getMilestones()}</Stepper>
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}
